'use client';

import { useEffect, useRef } from 'react';
import { Icon, IconName } from '~/components/core/Icon';
import { spacing } from '~/utils/tailwindUtils';
import Card from '../cards/Card';
import Flex from '../flex/Flex';
import { twMerge } from 'tailwind-merge';

type ModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  children: React.ReactNode;
  outsideClick?: boolean;
  size?: 'sm' | 'lg';
  classname?: string;
  isCloseButtonHidden?: boolean;
  modalClassname?: string;
  childrenDivWrapperClassName?: string;
};

/**
 * Provides a modal component that can be used to display content in a modal window with a close button.
 * The modal will close if the user clicks outside of it.
 * @param isOpen - boolean to determine if the modal is open
 * @param closeModal - function to close the modal
 * @param children - content to display in the modal
 * @param outsideClick - boolean to determine if the modal should close when clicking outside of it
 * @param size - size of the modal
 * @param modalClassname - custom class name for the modal
 * @returns Modal component
 */
const Modal = ({
  isOpen,
  closeModal,
  children,
  outsideClick,
  classname,
  isCloseButtonHidden = false,
  modalClassname,
  childrenDivWrapperClassName,
  size = 'lg', // TODO: Fix, find a better way: https://github.com/andercore-labs/buyer-app-ui/pull/615#discussion_r1708801903
}: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  // Close the modal if clicking outside of the modal
  useEffect(() => {
    if (outsideClick) {
      const handleOutsideClick = (event: MouseEvent) => {
        if (
          modalRef.current &&
          !modalRef.current.contains(event.target as Node)
        ) {
          closeModal();
        }
      };

      if (isOpen) {
        document.addEventListener('mousedown', handleOutsideClick);
      }

      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }
  }, [isOpen, closeModal, outsideClick]);

  if (!isOpen) return null;

  return (
    <Flex
      className={twMerge(
        'fixed inset-0 items-center justify-center bg-gray-900 bg-opacity-50 z-100',
        modalClassname
      )}
    >
      <Flex className="flex-col relative bg-white rounded-2xl">
        {!isCloseButtonHidden && (
          <Flex className="justify-end px-2 pt-2 z-90">
            <button
              className="text-gray-600 hover:bg-gray-50 rounded-full sticky pt-2 pl-2 "
              onClick={closeModal}
            >
              <Icon name={IconName.Cancel} size={spacing[7]} colorMode="fill" />
            </button>
          </Flex>
        )}
        <Card
          className={twMerge(
            'w-full md:w-11/12 justify-center self-center relative p-6 md:p-10 border-0',
            size === 'sm' && 'lg:p-10 pb-0',
            size === 'lg' && 'lg:p-20 pb-0',
            classname
          )}
          ref={modalRef}
        >
          <Flex
            className={twMerge(
              'flex-col items-center gap-4 pt-4 w-full',
              childrenDivWrapperClassName
            )}
          >
            {children}
          </Flex>
        </Card>
      </Flex>
    </Flex>
  );
};

export default Modal;
