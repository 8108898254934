'use client';

import { memo, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import Flex from '~/components/customComponents/flex/Flex';
import { useI18n } from '~/locales/client';
import { FormItemTypeEnum } from '~/types-and-enums/formFieldEnums';
import { WeekPickerModalProps } from '~/types-and-enums/generalTypes';
import { Counter } from '../counter/Counter';
import WeekPickerButton from '../datePicker/WeekPickerButton';
import ErrorText from '../error-text/ErrorText';
import HourMinutePicker from '../hourPicker/HourMinutePicker';
import PhoneInput from '../inputs/PhoneInput';
import TextArea from '../inputs/TextArea';
import { TextInput } from '../inputs/TextInput';
import RadioGroup from '../radioGroup/RadioGroup';
import SimpleSelect from '../select/SimpleSelect';
import FileUpload from '../FileUploader/FileUploader';

interface BaseInputProps {
  name?: string;
  value?: any;
  placeholder?: string;
  onChange?: (value: any) => void;
  onBlur?: (e: any) => void;
  classNames?: string;
  labelClassName?: string;
  inputFieldClassName?: string;
  isDisabled?: boolean;
}

interface TextAreaProps extends BaseInputProps {
  type: FormItemTypeEnum.TextArea;
  value: string;
  textAreaLimit?: number;
}

interface CounterProps extends BaseInputProps {
  type: FormItemTypeEnum.Counter;
  multiplier?: number;
  showWarning?: boolean;
}

interface SelectInputProps extends BaseInputProps {
  type: FormItemTypeEnum.SimpleSelect;
  selectOptions: {
    id: string | number;
    value: string | number;
    label?: string;
  }[];
  loading?: boolean;
}

interface PhoneInputProps extends BaseInputProps {
  type: FormItemTypeEnum.Phone;
}

interface HourPickerProps extends BaseInputProps {
  type: FormItemTypeEnum.HourPicker;
  openingHour?: boolean;
  closingHour?: boolean;
}

interface RadioGroupProps extends BaseInputProps {
  type: FormItemTypeEnum.RadioGroup;
  radioOptions: { id: string; label: string; value: string }[];
}

interface TextInputProps extends BaseInputProps {
  type: FormItemTypeEnum.Text | FormItemTypeEnum.Password;
}

type WeekPickerProps = Omit<WeekPickerModalProps, 'value' | 'onChange'> &
  BaseInputProps & {
    type: FormItemTypeEnum.WeekPicker;
  };
interface UploaderProps extends BaseInputProps {
  type: FormItemTypeEnum.Uploader;
  multiple?: boolean;
  labelClassName?: string;
}
interface HiddenInputProps extends BaseInputProps {
  type: FormItemTypeEnum.Hidden;
}

type InputProps =
  | TextInputProps
  | TextAreaProps
  | CounterProps
  | SelectInputProps
  | PhoneInputProps
  | HourPickerProps
  | RadioGroupProps
  | HiddenInputProps
  | WeekPickerProps
  | UploaderProps;

interface FormFieldBaseProps {
  label?: string;
  additionalHintLabel?: string;
  isInvalid?: boolean;
  errorMessage?: string;
  required?: boolean;
  verified?: boolean;
  loading?: boolean;
}

export type FormFieldProps = InputProps & FormFieldBaseProps;

/**
 * Input field component that is used to display input fields, textareas, counters, select inputs, phone inputs, hour pickers and radio groups
 * @param name - The name of the input field
 * @param value - The value of the input field
 * @param placeholder - The placeholder of the input field
 * @param onChange - The function to call when the input field value is changed
 * @param type - The type of the input field
 * @param classNames - The classes to be applied to the input field
 * @param isDisabled - The state of the input field
 * @param textAreaLimit - The limit of the text area
 * @param selectOptions - The options for the select input
 * @param radioOptions - The options for the radio group
 * @param onBlur - The function to call when the input field is blurred
 * @param label - The label of the input field
 * @param isInvalid - The state of the input field
 * @param errorMessage - The error message of the input field
 * @param required - The state of the input field
 * @returns Input field component
 */
const InputField = memo((props: InputProps) => {
  const { value, onChange, type, classNames, isDisabled } = props;
  const translate = useI18n();

  const handleEventChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      !isDisabled && onChange?.(event.target.value);
    },
    [onChange, isDisabled]
  );

  const handleValueChange = useCallback(
    (value: any) => {
      !isDisabled && onChange?.(value);
    },
    [onChange, isDisabled]
  );

  switch (type) {
    case FormItemTypeEnum.TextArea:
      return (
        <TextArea
          {...props}
          onChange={handleValueChange}
          classNames={classNames}
          limit={props.textAreaLimit}
        />
      );
    case FormItemTypeEnum.Counter:
      return (
        <Counter
          {...props}
          value={value}
          onChange={handleValueChange}
          classNames={classNames}
          isDisabled={props.isDisabled}
        />
      );
    case FormItemTypeEnum.SimpleSelect:
      const placeholder =
        props.placeholder ||
        translate('component.form-field.select.placeholder');
      const emptyLabel = translate('select.isEmpty.general');
      return (
        <SimpleSelect
          {...props}
          value={value}
          placeholder={placeholder}
          onChange={handleValueChange}
          options={props.selectOptions}
          emptyLabel={emptyLabel}
          loading={props.loading}
          isDisabled={isDisabled}
          selectContainerClassName={classNames}
        />
      );
    case FormItemTypeEnum.Phone:
      return (
        <PhoneInput
          {...props}
          value={value}
          onChange={handleValueChange}
          isDisabled={isDisabled}
          classNames={classNames}
        />
      );
    case FormItemTypeEnum.HourPicker:
      return (
        <HourMinutePicker
          {...props}
          value={value}
          onChange={handleValueChange}
          classNames={classNames}
          openingHour={props.openingHour}
          closingHour={props.closingHour}
        />
      );
    case FormItemTypeEnum.RadioGroup:
      return (
        <RadioGroup
          {...props}
          value={value}
          options={props.radioOptions}
          onChange={handleValueChange}
        />
      );
    case FormItemTypeEnum.Password:
      return (
        <TextInput
          {...props}
          value={value}
          onChange={handleEventChange}
          classNames={classNames}
          isDisabled={props.isDisabled}
        />
      );
    case FormItemTypeEnum.Text:
      return (
        <TextInput
          {...props}
          classNames={classNames}
          value={value}
          onChange={handleEventChange}
          isDisabled={props.isDisabled}
        />
      );
    case FormItemTypeEnum.WeekPicker:
      return (
        <WeekPickerButton
          {...props}
          value={value}
          classNames={classNames}
          isDisabled={isDisabled}
        />
      );
    case FormItemTypeEnum.Uploader:
      return <FileUpload {...props} isDisabled={isDisabled} />;
    case FormItemTypeEnum.Hidden:
      return <input type="hidden" value={value} name={props.name} />;
  }
});

InputField.displayName = 'InputField';

const FormItem = ({
  name,
  label,
  errorMessage,
  classNames,
  labelClassName,
  inputFieldClassName,
  required = false,
  isInvalid,
  isDisabled = false,
  additionalHintLabel,
  ...props
}: FormFieldProps) => {
  return (
    <Flex
      className={twMerge(
        'flex-col grow',
        isDisabled && 'opacity-50 hover:bg-gray-50 pointer-events-none',
        classNames
      )}
    >
      <label
        htmlFor={name}
        className={twMerge(
          'text-primary font-semibold text-xs mb-2',
          labelClassName
        )}
      >
        {label}
        {additionalHintLabel && (
          <span className=" font-normal text-xs ml-1">
            ({additionalHintLabel})
          </span>
        )}
      </label>

      <InputField
        {...props}
        isDisabled={isDisabled}
        name={name}
        classNames={twMerge(
          isInvalid || errorMessage
            ? 'border border-red-500'
            : 'border-transparent',
          inputFieldClassName
        )}
      />
      {errorMessage && (
        <ErrorText errorMessage={errorMessage} className="mt-1" />
      )}
    </Flex>
  );
};

export default FormItem;
