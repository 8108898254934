'use client';
import { twMerge } from 'tailwind-merge';
import { useCallback, useEffect, useRef, useState } from 'react';

type TextAreaProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  name?: string;
  rows?: number;
  classNames?: string;
  limit?: number;
};

/**
 * TextArea component that allows the user to input a text
 * @param value
 * @param onChange
 * @param placeholder
 * @param name
 * @param rows
 * @param classNames
 * @returns TextArea component
 */

const TextArea = ({
  value,
  onChange,
  placeholder,
  name,
  rows = 4,
  classNames,
  limit,
}: TextAreaProps) => {
  const [inputValue, setInputValue] = useState(value);
  const firstRender = useRef(true);

  const handleTextAreaChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange(e.target.value);
      setInputValue(e.target.value);
    },
    [onChange]
  );
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    setInputValue(value);
  }, [value]);
  return (
    <>
      <textarea
        name={name}
        id={name}
        rows={rows}
        maxLength={limit}
        placeholder={placeholder}
        className={twMerge(
          'w-full max-w-256 rounded-lg border border-gray-700 bg-gray-50 py-1.5 pl-3 text-primary  placeholder:text-gray-400 placeholder:text-xs sm:text-sm sm:leading-6 outline-none focus:ring-1 focus:ring-inset focus:ring-gray-300 hover:bg-gray-100',
          classNames
        )}
        value={inputValue}
        onChange={handleTextAreaChange}
      />
      {limit && (
        <span className="text-xs text-gray-800 ml-0.5 mt-0.5">
          {inputValue.length}/{limit}
        </span>
      )}
    </>
  );
};

export default TextArea;
