import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { NextAuthOptions, Session } from 'next-auth';
import { Suggestion } from '~/app/[locale]/home/store/_components/Search';
import { SearchResponse } from '~/app/[locale]/home/store/requests';
import { authOptions } from '~/app/api/auth/[...nextauth]/constants';
import {
  AcceptedCookiePolicyType,
  CookieTypeEnum,
} from '~/types-and-enums/cookieTypes';
import {
  DeliveryDetailsType,
  ShippingAddressType,
} from '~/types-and-enums/deliveryTypes';
import { EmptyObjectType } from '~/types-and-enums/generalTypes';
import { UserSettingsType } from '~/types-and-enums/settingsTypes';
import { ShoppingCartOrderType } from '~/types-and-enums/storeTypes';

export const cookieModalAtom = atom<boolean>(false);

export const acceptedCookiePolicyAtom = atom<AcceptedCookiePolicyType>(
  CookieTypeEnum.ErrorTracking
);

export const wantsStoreIsLiveNotification = atom<boolean>(false);

export const openDarkBackgroundAtom = atom<boolean>(false);

export const isSearchActiveAtom = atom<boolean>(false);
export const isLoadingAtom = atom<boolean>(false);

export const searchValueAtom = atom<string>('');
export const searchSuggestionAtom = atom<Suggestion[] | null>(null);
export const shippingAddressAtom = atom<ShippingAddressType | EmptyObjectType>(
  {}
);

// we change it to null to avoid the flickering of the page,it wont cause issues since
// we trigger this in root layout and if some values are not provided we return EMTY_USER_SETTINGS in request
export const userSettingsAtom = atom<UserSettingsType | null>(null);
export const isMobileAtom = atom<boolean>(false);
export const userSessionAtom = atom<Session | NextAuthOptions>(authOptions);

export const loadingCart = atom(false);

export const cartDetailsAtom = atom<ShoppingCartOrderType>(
  {} as ShoppingCartOrderType
);

export const latestProductUserVisited = atom('');

export const searchResponseAtom = atomWithStorage<SearchResponse | null>(
  'searchResponse',
  null
);

export const similarFEsInDeliveryDetailsPage = atom<DeliveryDetailsType[]>([]);

export type Filters = {
  category?: string;
  priceRange?: [number, number];
  brand?: string;
  deliveryTime?: string;
  page?: number;
};

export const numberOfDisplayedComments = atom<number>(0);
