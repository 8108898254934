'use client';

import { useCallback, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Icon, IconName } from '~/components/core/Icon';
import Text from '~/components/customComponents/texts/Text';
import { ColorModeEnum } from '~/utils/generalUtils';
import { colors, spacing } from '~/utils/tailwindUtils';
import Flex from '../flex/Flex';

export interface FormFieldProps {
  value: string;
  onChange?: (value: any) => void;
  name?: string;
  classNames?: string;
  multiplier?: number;
  isDisabled?: boolean;
  showWarning?: boolean;
}

/**
 * Counter component that is used to display a counter with a plus and minus button to increase and decrease the counter value respectively
 * @param name - The name of the counter
 * @param onChange - The function to call when the counter value is changed
 * @param value - The value of the counter
 * @param classNames - The classes to be applied to the counter
 * @param multiplier - The multiplier to be used when increasing or decreasing the counter value
 * @returns Counter component
 */
export const Counter = ({
  name,
  onChange = () => {},
  value,
  classNames,
  multiplier,
  isDisabled = false,
  showWarning,
}: Omit<FormFieldProps, 'label'>) => {
  const [inputValue, setInputValue] = useState<number>(Number(value));
  const multiplierOr1 = multiplier || 1;

  // Update input value when the `value` prop changes
  useEffect(() => {
    if (Number(value) !== inputValue) setInputValue(Number(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // Handle value changes and trigger API request
  const triggerAPIRequest = useCallback(
    (newValue: number) => onChange(newValue),
    [onChange]
  );

  // Handle input change
  const handleInputChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = evt.target.value;
      if (newValue === '' || /^[0-9]*$/.test(newValue)) {
        setInputValue(Number(newValue));
      }
    },
    []
  );

  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === 'Enter') {
      evt.preventDefault();
      evt.stopPropagation();

      // Simulate handleInputChange behavior
      const newValue = evt.currentTarget.value;
      if (/^[0-9]*$/.test(newValue)) {
        triggerAPIRequest(Number(newValue));
      }
    }
  };

  // Handle increase button click
  const handleIncrease = useCallback(() => {
    const newValue = inputValue + multiplierOr1;
    triggerAPIRequest(newValue);
  }, [inputValue, multiplierOr1, triggerAPIRequest]);

  // Handle decrease button click
  const handleDecrease = useCallback(() => {
    const newValue = Math.max(inputValue - multiplierOr1, multiplierOr1);
    triggerAPIRequest(newValue);
  }, [inputValue, multiplierOr1, triggerAPIRequest]);

  // Determine if the decrease button should be disabled
  const isDecreaseDisabled = inputValue === multiplierOr1;

  return (
    <Flex
      className={twMerge(
        'bg-gray-50 gap-2 rounded-lg px-4 py-2',
        isDisabled && 'pointer-events-none',
        classNames
      )}
    >
      {showWarning && (
        <Flex className="items-center">
          <Icon name={IconName.AlertTriangle} color={colors.orange[500]} />
        </Flex>
      )}

      {/* Decrease Button */}
      <Flex
        className={twMerge(
          'cursor-pointer flex-1 items-center gap-1',
          (isDecreaseDisabled || isDisabled) && 'opacity-50 cursor-not-allowed'
        )}
        onClick={handleDecrease}
        data-testid="decrease-button"
      >
        {multiplierOr1 > 1 && (
          <Text className="text-gray-500 text-2xs md:text-xs font-medium">
            {multiplier}
          </Text>
        )}
        <Icon
          name={IconName.Minus}
          width={spacing[multiplier ? 2 : 3]}
          height={spacing[4]}
          color={colors.gray[500]}
          customStrokeWidth={6}
          colorMode={ColorModeEnum.Fill}
          className={twMerge('pt-px')}
        />
      </Flex>

      {/* Input Field */}
      <input
        name={name}
        id={name}
        type="text" // Change to text for better control
        inputMode="numeric" // Shows numeric keyboard on mobile
        pattern="[0-9]*" // Enforces numeric input
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={(e) => triggerAPIRequest(Number(e.target.value))}
        className={
          'text-black text-center text-sm md:text-base font-medium w-full focus:outline-none flex-6 bg-gray-50'
        }
        disabled={isDisabled}
        aria-disabled={isDisabled}
      />

      {/* Increase Button */}
      <Flex
        className={twMerge(
          'cursor-pointer flex-1 justify-end items-center gap-1',
          isDisabled && 'cursor-not-allowed'
        )}
        onClick={handleIncrease}
        data-testid="increase-button"
      >
        <Icon
          name={IconName.Add}
          size={spacing[multiplier ? 3 : 4]}
          color={colors.gray[500]}
          colorMode={ColorModeEnum.Fill}
          customStrokeWidth={3}
        />
        {multiplierOr1 > 1 && (
          <Text className="text-gray-500 text-2xs md:text-xs font-medium">
            {multiplier}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};
