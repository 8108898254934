import { twMerge } from 'tailwind-merge';
import { Icon, IconName } from '~/components/core/Icon';
import { FormItemTypeEnum } from '~/types-and-enums/formFieldEnums';
import { colors, spacing } from '~/utils/tailwindUtils';
import Flex from '../flex/Flex';

export interface FormFieldProps {
  onChange?: (e: any) => void;
  label?: string;
  isInvalid?: boolean;
  errorMessage?: string;
  required?: boolean;
  onBlur?: (e: any) => void;
  value?: any;
  name?: string;
  placeholder?: string;
  classNames?: string;
  isDisabled?: boolean;
  type?: FormItemTypeEnum.Text | FormItemTypeEnum.Password;
  verified?: boolean;
  loading?: boolean;
}
/**
 * TextInput component that allows the user to input a text value in a form
 * @param name
 * @param placeholder
 * @param onChange
 * @param onBlur
 * @param value
 * @param isDisabled
 * @param classNames
 * @param type
 * @param verified
 * @param loading
 * @returns TextInput component
 */

export const TextInput = ({
  name,
  placeholder,
  onChange,
  onBlur,
  value,
  isDisabled,
  classNames,
  verified,
  type = FormItemTypeEnum.Text,
  loading,
}: Omit<FormFieldProps, 'label'>) => {
  return (
    <Flex className="relative">
      <input
        name={name}
        id={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={isDisabled}
        className={twMerge(
          'bg-gray-50 hover:bg-gray-100 px-5 p h-10 text-black border border-transparent rounded-lg text-sm md:text-base font-medium w-full max-w-256 focus:outline-none focus:border-gray-300  placeholder:text-xs',
          classNames
        )}
      />
      {!loading && typeof verified === 'boolean' ? (
        <Icon
          name={verified ? IconName.CheckCircle : IconName.CancelCircle}
          color={verified ? colors.green[500] : colors.red[500]}
          size={spacing[6]}
          className="absolute right-2 top-2 border-l border-gray-200 pl-2"
        />
      ) : loading ? (
        <Icon
          name={IconName.Loader}
          size={spacing[5]}
          color={colors.gray[500]}
          className="absolute right-2 top-3  animate-spin"
        />
      ) : null}
    </Flex>
  );
};
