'use client';

import Modal from '../modal/Modal';
import { useSearchParams } from 'next/navigation';
import { IS_WEEK_PICKER_MODAL_OPEN } from '~/constants/queryParams';
import {
  getDeviceTypeClientSide,
  updateSearchParams,
} from '~/utils/clientUtils';
import Flex from '~/components/customComponents/flex/Flex';
import { Icon, IconName } from '~/components/core/Icon';
import { colors, spacing } from '~/utils/tailwindUtils';
import Text from '~/components/customComponents/texts/Text';
import { useAtom } from 'jotai';
import { openDarkBackgroundAtom } from '~/atoms';
import Button from '~/components/customComponents/buttons/Button';
import { useState } from 'react';
import { getWeekNumber } from '~/utils/dateUtils';
import Weekpicker from './Weekpicker';
import Card from '../cards/Card';
import MobileNavPopup from '../modal/MobileNavPopop';
import { ColorModeEnum } from '~/utils/generalUtils';
import { useI18n } from '~/locales/client';
import { twMerge } from 'tailwind-merge';
import { WeekPickerModalProps } from '~/types-and-enums/generalTypes';

const isLoading = false;
/**
 * A functional component that renders the week picker button. It contains the week picker modal.
 * @param {WeekPickerModalProps} props The props for the component
 * @return {JSX.Element} The week picker button component
 */
const WeekPickerButton = ({
  value,
  firstAvailableDate,
  lastAvailableDate,
  onChange,
  submitAction,
  modalHeaderTitle,
  modalHeaderBody,
  labelText,
  confirmButtonText,
  classNames,
  uniqueQueryId,
  isDisabled,
  showWarning,
  placeholder = '',
}: WeekPickerModalProps) => {
  const searchParamOpenModal = uniqueQueryId
    ? IS_WEEK_PICKER_MODAL_OPEN + uniqueQueryId
    : IS_WEEK_PICKER_MODAL_OPEN;
  const searchParams = useSearchParams();
  const isWeekPickerOpen = Boolean(searchParams.get(searchParamOpenModal));
  const { isMobile } = getDeviceTypeClientSide();
  const translate = useI18n();
  const [, setIsOpenBackground] = useAtom(openDarkBackgroundAtom);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const handleCloseWeekPicker = () => {
    const params = new URLSearchParams(searchParams);
    params.delete(searchParamOpenModal);
    updateSearchParams(params);
    setIsOpenBackground(false);
  };
  const handleSubmitClick = () => {
    handleCloseWeekPicker();
    submitAction && submitAction();
  };
  const handleChangeDate = (date: Date) => {
    onChange && onChange(date);
    if (value && getWeekNumber(date) === getWeekNumber(new Date(value))) {
      setSelectedDate(null);
      onChange && onChange(value);
    } else {
      setSelectedDate(date);
      onChange && onChange(date);
    }
  };

  const handleOpenWeekPicker = () => {
    const params = new URLSearchParams(searchParams);
    !isDisabled && params.set(searchParamOpenModal, 'true');
    updateSearchParams(params);
    setIsOpenBackground(true);
  };

  return (
    <>
      <Flex
        className={twMerge(
          'relative w-full md:min-w-72 bg-gray-50 gap-2 px-4 py-2 h-10 rounded-lg cursor-pointer border',
          isDisabled && 'cursor-not-allowed',
          classNames
        )}
        onClick={handleOpenWeekPicker}
      >
        {showWarning && (
          <Flex className="items-center flex-1">
            <Icon
              name={IconName.AlertTriangle}
              color={colors.orange[500]}
              size={spacing[6]}
            />
          </Flex>
        )}
        <Flex className="flex-6 items-center">
          <Text
            className={twMerge(
              'text-xs md:text-sm md:leading-4 font-medium',
              placeholder && !labelText && 'text-gray-400'
            )}
          >
            {labelText || placeholder}
          </Text>
        </Flex>
        <Flex className="items-center justify-end flex-1">
          <Icon
            name={IconName.Calendar}
            size={spacing[5]}
            color={colors.gray[500]}
          />
        </Flex>
      </Flex>
      {isMobile ? (
        <Card className="fixed bottom-0 left-0 w-screen rounded-b-none p-0 md:hidden gap-0 bg-transparent border-none z-90">
          <MobileNavPopup
            showMobileNavPopover={isWeekPickerOpen}
            handleSwitchMobileNavPopover={handleCloseWeekPicker}
            className="items-center h-[70dvh]"
          >
            <Icon
              name={IconName.Cancel}
              size={spacing[6]}
              colorMode={ColorModeEnum.Fill}
              className="justify-end pt-4"
              onClick={handleCloseWeekPicker}
            />
            <Flex className=" w-full flex-col ">
              <Flex className="gap-x-4">
                <Icon name={IconName.Calendar} width={spacing[6]} />
                <Text className="text-lg font-semibold">
                  {modalHeaderTitle}
                </Text>
              </Flex>
              <Text className="text-xs text-gray-500 pl-10">
                {modalHeaderBody}
              </Text>
            </Flex>
            <Weekpicker
              firstAvailableDate={firstAvailableDate}
              lastAvailableDate={lastAvailableDate}
              deliveryDate={value}
              onChange={onChange}
            />
            {submitAction && (
              <Flex className="justify-between gap-x-4 w-full pt-4">
                <Button
                  text={translate('general.cancel')}
                  type="button"
                  buttonClassName="w-24"
                  buttonStyle="default"
                  onClick={handleCloseWeekPicker}
                />
                <Button
                  text={translate(confirmButtonText || '')}
                  type="submit"
                  buttonClassName="w-24"
                  isLoading={isLoading}
                  disabled={isLoading || !selectedDate}
                  onClick={handleSubmitClick}
                />
              </Flex>
            )}
          </MobileNavPopup>
        </Card>
      ) : (
        <Modal
          isOpen={isWeekPickerOpen}
          closeModal={handleCloseWeekPicker}
          modalClassname="bg-opacity-15"
          classname="lg:p-10 lg:pt-0 lg:w-120"
        >
          <Flex className=" w-full flex-col ">
            <Flex className="gap-x-4">
              <Icon name={IconName.Calendar} width={spacing[6]} />
              <Text className="text-lg font-semibold">{modalHeaderTitle}</Text>
            </Flex>
            <Text className="text-xs text-gray-500 pl-10">
              {modalHeaderBody}
            </Text>
          </Flex>
          <Weekpicker
            firstAvailableDate={firstAvailableDate}
            lastAvailableDate={lastAvailableDate}
            deliveryDate={value}
            onChange={handleChangeDate}
          />
          {Boolean(submitAction) && (
            <Flex className="justify-between gap-x-4 w-full">
              <Button
                text={translate('general.cancel')}
                type="button"
                buttonClassName="w-24 bg-white border-gray-300"
                buttonStyle="default"
                onClick={handleCloseWeekPicker}
              />
              <Button
                text={translate(confirmButtonText || '')}
                type="submit"
                buttonClassName="w-24"
                isLoading={isLoading}
                disabled={isLoading || !selectedDate}
                onClick={handleSubmitClick}
              />
            </Flex>
          )}
        </Modal>
      )}
    </>
  );
};

export default WeekPickerButton;
