'use client';

import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import Flex from '../flex/Flex';
import { Icon, IconName } from '~/components/core/Icon';
import { colors, spacing } from '~/utils/tailwindUtils';
import { useI18n } from '~/locales/client';
import { twMerge } from 'tailwind-merge';

type HourMinutePickerProps = {
  value: string;
  onChange: (newValue: string) => void;
  name?: string;
  classNames?: string;
  openingHour?: boolean;
  closingHour?: boolean;
};

// Generate hours (00 - 23)
const hours = Array.from({ length: 24 }, (_, i) => String(i).padStart(2, '0'));

// Generate minutes
const minutes = ['00', '15', '30', '45'];

/**
 * HourMinutePicker component is a custom component that allows the user to select hours and minutes. It is used in the TimePicker component.
 * @param value - The value of the selected hour and minute.
 * @param onChange - The function that is called when the selected hour or minute changes.
 * @param name - The name of the input field.
 * @returns A custom component that allows the user to select hours and minutes.
 */

const HourMinutePicker = ({
  value,
  onChange,
  name,
  classNames,
  openingHour,
  closingHour,
}: HourMinutePickerProps) => {
  const translate = useI18n();
  const firstRender = useRef(true);
  const [selectedHour, setSelectedHour] = useState(
    value?.slice(0, 2) || openingHour ? '08' : closingHour ? '16' : '00'
  );
  const [selectedMinute, setSelectedMinute] = useState(
    value?.slice(3, 5) || '00'
  );

  // Set selected hour and minute on value change (initial value) and update the value on change of selected hour and minute
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (!value) return;
    setSelectedHour(value.slice(0, 2));
    setSelectedMinute(value.slice(3, 5));
  }, [value]);

  const handleHourChange = useCallback(
    (evt: string) => {
      setSelectedHour(evt);
      onChange(`${evt}:${selectedMinute}`);
    },
    [selectedMinute, onChange]
  );

  const handleMinuteChange = useCallback(
    (evt: string) => {
      setSelectedMinute(evt);
      const newMinutes = evt.slice(0, 2);
      onChange(`${selectedHour}:${newMinutes}`);
    },
    [selectedHour, onChange]
  );

  return (
    <Flex
      className={twMerge('gap-x-0.5 w-full max-w-132 rounded-lg', classNames)}
    >
      <Listbox value={selectedHour} onChange={handleHourChange}>
        <div className="relative basis-1/2">
          <ListboxButton
            id={name}
            className="relative bg-gray-50 w-full h-10 pl-4 md:pl-16 pr-10 rounded-lg rounded-r-none  text-left text-gray-900  focus:ring-1 focus:ring-inset focus:ring-gray-300 hover:bg-gray-100 sm:text-sm sm:leading-6 cursor-pointer"
          >
            <label className="text-xs font-medium text-gray-700 absolute top-[11px] left-2 hidden md:block">
              {translate('pages.general.hours-picker-label')}
            </label>
            <Flex className="w-full justify-center ">
              <span
                className={twMerge(
                  'block truncate font-semibold',
                  !value && 'text-gray-500'
                )}
              >
                {selectedHour}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-1 flex items-center pr-2">
                <Icon
                  size={spacing[5]}
                  name={IconName.ChevronDown}
                  color={colors.gray[500]}
                />
              </span>
            </Flex>
          </ListboxButton>

          <ListboxOptions
            transition
            className="absolute z-80 mt-1 max-h-[220px] w-full overflow-auto rounded-lg bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
          >
            {hours.map((hour) => (
              <ListboxOption
                key={hour}
                value={hour}
                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-gray-300"
              >
                <span className="block truncate font-normal group-data-[selected]:font-semibold">
                  {hour}
                </span>
              </ListboxOption>
            ))}
          </ListboxOptions>
        </div>
      </Listbox>
      <Listbox value={selectedMinute} onChange={handleMinuteChange}>
        <div className="relative  basis-1/2">
          <ListboxButton
            id={name}
            className="relative flex items-center justify-between bg-gray-50 w-full h-10 pl-4 rounded-l-none md:pl-16 rounded-md  md:border-l-0 bg-whitepl-3 pr-10 text-left text-gray-900 sm:text-sm sm:leading-6 focus:ring-1 focus:ring-inset focus:ring-gray-300 hover:bg-gray-100 cursor-pointer"
          >
            <label className="text-xs font-medium absolute top-[11px] left-2 hidden md:block">
              {translate('pages.general.minutes-picker-label')}
            </label>
            <Flex className="w-full justify-center">
              <span
                className={twMerge(
                  'block truncate font-semibold',
                  !value && 'text-gray-500'
                )}
              >
                {selectedMinute}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-1 flex items-center pr-2">
                <Icon
                  size={spacing[5]}
                  name={IconName.ChevronDown}
                  color={colors.gray[500]}
                />
              </span>
            </Flex>
          </ListboxButton>

          <ListboxOptions
            transition
            className="absolute z-80 mt-1 max-h-[220px]  w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm "
          >
            {minutes.map((minute) => (
              <ListboxOption
                key={minute}
                value={minute}
                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-gray-300 "
              >
                <span className="block truncate font-normal group-data-[selected]:font-semibold">
                  {minute}
                </span>
              </ListboxOption>
            ))}
          </ListboxOptions>
        </div>
      </Listbox>
    </Flex>
  );
};

export default HourMinutePicker;
