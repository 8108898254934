'use client';
import { useCallback, useState } from 'react';
import Flex from '../flex/Flex';
import { useI18n, useScopedI18n } from '~/locales/client';
import Text from '../texts/Text';
import { Icon, IconName } from '~/components/core/Icon';
import { colors, spacing } from '~/utils/tailwindUtils';
import { addCommaToNumber } from '~/utils/generalUtils';
import { twMerge } from 'tailwind-merge';

type FileUploadProps = {
  onChange?: (value: File[]) => void;
  limit?: number;
  isDisabled?: boolean;
  multiple?: boolean;
  acceptFileTypes?: string;
  labelClassName?: string;
};
// default file size limit in MB
const MAX_FILE_SIZE_MB = 5;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;
const DEFAULT_FILE_TYPES = '.pdf,.jpeg,.jpg,.png,.xls,.xlsx,.doc,.docx,.zip';

/**
 * FileUpload component that allows the user to upload files to the server
 * @param onChange - function that is called when the user uploads a file
 * @param limit - the maximum number of files that can be uploaded by MB
 * @returns FileUpload component
 */
const FileUpload = ({
  onChange,
  limit = MAX_FILE_SIZE_BYTES,
  isDisabled,
  multiple,
  acceptFileTypes = DEFAULT_FILE_TYPES,
  labelClassName,
}: FileUploadProps) => {
  const translate = useI18n();
  const scopeTranslate = useScopedI18n('component.fileUploader');
  const [files, setFiles] = useState<File[]>([]);

  const handleFiles = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFiles = event.target.files
        ? Array.from(event.target.files)
        : [];

      // Filter out files that exceed the size limit
      const validFiles = selectedFiles.filter((file) => file.size <= limit);

      if (validFiles.length === 0) return;

      const newFiles = multiple ? [...files, ...validFiles] : [validFiles[0]];
      setFiles(newFiles);
      onChange && onChange(newFiles);
    },
    [files, limit, multiple, onChange]
  );

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLLabelElement>) => {
      event.preventDefault();
      const droppedFiles = Array.from(event.dataTransfer.files).filter(
        (file) => file.size <= limit
      );

      if (droppedFiles.length === 0) return;

      const newFiles = multiple
        ? [...files, ...droppedFiles]
        : [droppedFiles[0]];
      setFiles(newFiles);
      onChange && onChange(newFiles);
    },
    [files, limit, multiple, onChange]
  );

  const removeItem = useCallback(
    (index: number) => {
      setFiles((prevFiles) => {
        const newFiles = prevFiles.filter((_, i) => i !== index);
        onChange && onChange(newFiles);
        return newFiles;
      });
    },
    [onChange]
  );

  return (
    <Flex className="w-full mx-auto flex-col gap-y-4">
      <label
        className={twMerge(
          'flex flex-col items-center justify-center w-full h-40 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100',
          labelClassName
        )}
        onDragOver={(e) => e.preventDefault()}
        onDrop={handleDrop}
        data-testid="file-upload-label"
      >
        <input
          type="file"
          multiple={multiple}
          className="hidden"
          onChange={handleFiles}
          accept={acceptFileTypes}
          disabled={isDisabled}
          data-testid="file-upload-input"
        />
        <Flex className="flex-col items-center gap-4 p-4">
          <Icon name={IconName.Upload} color={colors.black} />
          <Text className="text-black font-semibold text-sm md:text-lg">
            <div
              dangerouslySetInnerHTML={{
                __html: scopeTranslate(`title`),
              }}
            />
          </Text>
          <Text className="text-gray-500">
            {translate('component.fileUploader.description')}
          </Text>
        </Flex>
      </label>
      {files.length > 0 && (
        <ul className="mt-4 space-y-2">
          <Text className="font-semibold text-lg">
            {translate('general.files')}
          </Text>
          {files.map((file, index) => (
            <Flex
              key={`${file.name}-${file.lastModified}-${index}`}
              className="gap-2 py-1 border-b border-gray-100 justify-between items-center"
            >
              <Flex className="gap-x-4">
                <Icon name={IconName.Document} size={spacing[4]} />
                <li className="text-sm font-medium truncate max-w-100">
                  {file.name}
                </li>
              </Flex>
              <Flex className="gap-x-4">
                <Flex className="px-2 py-1 bg-gray-100 rounded-full">
                  <Text className="text-xs text-gray-500 min-w-max">
                    {addCommaToNumber(file.size / 1000)} KB
                  </Text>
                </Flex>
                <Icon
                  name={IconName.Trash}
                  size={spacing[4]}
                  onClick={() => removeItem(index)}
                  className="cursor-pointer"
                  data-testid="remove-file"
                />
              </Flex>
            </Flex>
          ))}
        </ul>
      )}
    </Flex>
  );
};

export default FileUpload;
