import { twMerge } from 'tailwind-merge';
import Text from '../texts/Text';

type ErrorTextProps = {
  className?: string;
  errorMessage?: string;
  children?: React.ReactNode;
};

/**
 * Displays an error message in red color by default
 * @param className - Additional classes to be added to the error text.
 * @param errorMessage - The error message to be displayed.
 * @param children - The content to be displayed in the error text.
 * @returns
 * - If errorMessage is provided, children will be ignored.
 * - If children is provided, errorMessage will be ignored.
 * - If both are provided, then children will be displayed.
 * - If both both are not provided, nothing will be displayed. returns null
 */
const ErrorText = ({ className, children, errorMessage }: ErrorTextProps) => {
  if (!errorMessage && !children) return null;

  return (
    <Text
      className={twMerge(
        'text-secondary text-2xs leading-3 font-semibold md:text-2xs md:leading-3',
        className
      )}
    >
      {children || errorMessage}
    </Text>
  );
};

export default ErrorText;
